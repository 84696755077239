@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';

.ctaListWrapperContainer {
  display: flex;
  padding-block: 32px;
  padding-left: 16px;
  gap: 8px;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  min-height: 55px;

  @include laptop-bp() {
    flex-wrap: wrap;
    gap: 16px;
    padding: 32px;
  }
  .buttonWrapper {
    display: flex;
  }
  .buttonWrapper:last-child {
    padding-right: 16px;
  }
}
